import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MaterialModule } from '../material-module';

import { SnackbarComponent } from './snackbar/snackbar.component';
import { AutofocusDirective } from './autofocus.directive';
import { DialogModule } from './dialog/dialog.module';
import { PipesModule } from './pipes/pipes.module';
import { SnackbarModule } from './snackbar/snackbar.module';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';

@NgModule({ declarations: [
        SnackbarComponent,
        AutofocusDirective,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        DialogModule,
        PipesModule,
        SnackbarModule,
        SnackbarComponent,
        AutofocusDirective,
        PageNotFoundModule,
    ], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule {
}

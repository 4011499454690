<div>
  <h2 class="mat-dialog-title text-center">{{args.header}}</h2>
  <mat-dialog-content>
    <h3 [innerHTML]="args.message | safe: 'html'"></h3>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close
      *ngIf="args.rejectVisible"
      [class]="args.rejectButtonStyleClass"
      (click)="args.reject(ref)">{{args.rejectLabel}}</button>
    <button mat-button
      *ngIf="args.acceptVisible"
      [class]="args.acceptButtonStyleClass"
      (click)="args.accept(ref)">{{args.acceptLabel}}</button>
  </mat-dialog-actions>
</div>
